@font-face {
  font-family: RioGrande;
  src: url('static/fonts/RioGrande.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: RioGrandeStripped;
  src: url('static/fonts/RioGrandeStriped.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: WesternBangBang;
  src: url('static/fonts/WesternBangBang.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: WesternBangBang;
  src: url('static/fonts/WesternBangBang.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #1B1C22; /* #f9fafc; */
}

* {
  box-sizing: border-box;
}

.no-focus:focus {
  outline: none;
  box-shadow: none;
}

/* elrond dapp tools overwrites - there is no other way at the moment and I don't want to use react-bootstrap */
.card-body {
  text-align: center;
  background-color: #23242A;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-body h4.mb-4,
.card-body h4.mb-3 {
  font-size: 24px;
  margin-top: 0;
  color: #ecf0f1 !important;
}

.card-body h4.mb-3 {
  margin-bottom: 10px;
}

.card-body h4.mb-4,
.card-body h4.mb-3,
.card-body p.lead.mb-0,
.card-body p.mb-4,
.card-body a.btn.btn-primary {
  text-align: center;
  font-family: 'SF UI Text', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
    color: #d7dde0 !important
}

.ub-pt_30px {
  background-color: #23242A !important;
  border-radius: 10px;
}

.card-body .btn.btn-primary {
  margin: 10px;
  margin-top: 40px;
  padding: 10px;
  display: -webkit-inline-flex;
  background: #23242A;
  border: 1px solid #c1c4d6;
  border-radius: 4px;
  cursor: pointer;
  color: #d7dde0;
}

.card-body a.btn.btn-primary {
  color: #d7dde0;
  font-size: 13.3333px;
  text-decoration: none;
}

.state.m-auto.p-4.text-center {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
/* elrond dapp tools overwrites end */

#preview-nft {
  max-width: 400px;
  height: auto;
  margin: 10px 40px 0px 20px;
  border-radius: 25px;
}

h1, .h2, h3, .h4 {
  font-family: RioGrande;
  font-weight: 600;
  color: #ecf0f1 !important;
}

h3 {
  font-size: 16px;
}

.form-group {
  color: #ecf0f1 !important;
}

.text {
  color: #d7dde0 !important;
}

.card {
  background-color: #23242A !important;
}

#My\ NFTs, #Mint {
  box-shadow: none;
}

.twitter {
  margin: 10px 10px 0px 20px;
}

.discord {
  margin: 10px 10px 0px 10px;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
}

.modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}

.modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
}

.modal-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1rem;
}

.state.m-auto.p-4.text-center {
    width: auto;
    padding: 20px;
    height: auto;
    min-height: 320px;
}

.countdown {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 45px;
  color: #d7dde0;
  text-align: center;
}

.smartcontract {
  margin-top: 50px;
  font-size: 25px;
  color: #3366FF;
}

.MadeWith {
  padding-top: 50px;
  font-size: 18px;
}

.ub-pb_40px {
    padding-bottom: 20px;
}

.rs-input-number {
  color: black !important;
}

#input-number-moo-staking {
  font-size: 18px;
  color: black !important;
  text-align: center;
  prefix: black !important;
  background-color: white;
}

#drawer-filter {
  color: white !important;
  background-color: #23242A !important;
}

#drawer-filter-title {
  font-size: 18px;
  color: white !important;
  background-color: #23242A !important;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 600px;
        margin: 1.75rem auto;
    }
    .modal-dialog-centered {
        min-height: 300px;
    }
    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 992px) {
    .modal-lg {
        max-width: 800px;
    }
}

@media screen and (max-width: 500px){
  #preview-nft {
    max-width: 300px;
  }
  #roadmap {
    font-size: 40px;
  }
  #finance {
    font-size: 40px;
  }
  .twitter {
    margin: 5px 2px 0px 20px; 
  }
  .discord {
    margin: 5px 2px 0px 4px; 
  }
  .h2 {
    font-size: 15px;
  }
  #titleElrond {
    margin-left: -10px;
  }
  .smartcontract {
    font-size: 16px;
    word-break: break-all;
    text-align: center;
  }
  #smartcontract {
    font-size: 36px;
  }
}
